import { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { withStyles, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import TimezoneIcon from '@material-ui/icons/AccessTime';
import { CurbhubLogo } from '../../assets/images';
import routes from '../../utils/routes';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { isDevMode } from '../../utils/devmode';
import moment from 'moment-timezone';
import { PlaylistPlay } from '@material-ui/icons';
import './SideDrawer.scss';
import { ApiQueueInfoContext } from '../../App';

const drawerWidth = 240;

const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    background: 'linear-gradient(to top, rgba(11,118,199,1) 0%, rgba(4,96,174,1) 100%)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1',
    background: 'rgba(0, 0, 0, 0.8)',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: '100vh',
      left: '0',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  item: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    '&:hover,&:focus,&:visited,&': {
      color: 'rgba(11,118,199,1)',
    },
  },
  itemLink: {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '0.5rem 0.5rem 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 8px 10px 15px',
    backgroundColor: 'transparent',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  itemIcon: {
    width: '24px',
    height: '30px',
    fontSize: '24px',
    lineHeight: '30px',
    float: 'left',
    marginRight: '20px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    minWidth: '24px',
  },
  itemText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    margin: '0',
    lineHeight: '30px',
    fontSize: '15px',
    color: 'white',
    textDecoration: 'none',
  },
  list: {
    marginTop: '20px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    position: 'unset',
  },
  logo: {
    width: '80%',
    position: 'relative',
    padding: '15px 15px 0px 15px',
    zIndex: '4',
    margin: '0 0.5rem',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      width: '100%',
      backgroundColor: 'rgba(180, 180, 180, 0.3)',
    },
  },
  logoLink: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    padding: '5px 0 10px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    '&,&:hover': {
      color: '#FFFFFF',
    },
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: '240px',
    zIndex: '4',
    overflowScrolling: 'touch',
  },
  img: {
    height: '32px',
  },
});

const SideDrawer = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { classes } = props;

  const [open, setOpen] = useState(false);
  const [localPath, setLocalPath] = useState(null);

  const apiQueueContext = useContext(ApiQueueInfoContext);

  const isDOCreation = location.pathname === '/delivery-orders/create';

  const redirect = (path) => {
    if (isDOCreation) {
      setLocalPath(path);
      setOpen(true);
    } else {
      history.push(path);
    }
  };

  const confirmRedirect = () => {
    setOpen(false);
    history.push(localPath);
  };

  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect || !prop.sidebarName) return null;
        if (!prop.hasPermission) return null;
        return (
          <div
            onClick={() => redirect(prop.path)}
            to={prop.path}
            className={`${classes.item} ${location.pathname.startsWith(prop.path) && 'Active'}`}
            key={key}
          >
            <ListItem button className={classes.itemLink}>
              <ListItemIcon className={classes.itemIcon}>
                <img src={prop.icon} alt="icon" width={28} />
              </ListItemIcon>
              <ListItemText primary={prop.sidebarName} className={classes.itemText} disableTypography={true} />
            </ListItem>
          </div>
        );
      })}
    </List>
  );

  const isSameTimezone =
    moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('Z') == moment().format('Z');

  const timezone = (
    <div style={{ display: 'flex', alignItems: 'center', padding: 16 }}>
      <TimezoneIcon style={{ display: 'inline-flex', color: 'gray', height: 40, width: 40 }} />
      <div style={{ margin: 8 }}>
        <div style={{ width: '100%', color: 'white' }}>{props.timezone}</div>
        <div style={{ width: '100%', color: isSameTimezone ? 'lightgray' : 'yellow' }}>{props.time}</div>
      </div>
    </div>
  );

  var brand = (
    <div className={classes.logo}>
      <Link to="/" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={CurbhubLogo} alt="CurbhubLogo" className={classes.img} />
        </div>
      </Link>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerOpen}>
          <Menu />
        </IconButton>
        <Drawer
          anchor="left"
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={props.open}
          onClose={props.handleDrawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper} onClick={props.handleDrawerOpen}>
            {links}
          </div>
          {timezone}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {apiQueueContext?.sum ? (
            <div style={{ display: 'flex', alignItems: 'center', padding: 16, paddingBottom: 4 }}>
              <PlaylistPlay
                style={{ display: 'inline-flex', color: 'gray', height: 48, width: 48 }}
                className="blink"
              />
              <div style={{ margin: 8, marginLeft: 0 }}>
                <div style={{ width: '100%', color: 'white' }}>API Call Queue</div>
                <div style={{ width: '100%', color: 'yellow' }}>Total Task: {apiQueueContext.sum}</div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {timezone}
          {isDevMode && <div style={{ color: 'red' }}>DEV Mode</div>}
        </Drawer>
      </Hidden>
      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        title="Redirect confirmation"
        description="If you proceed, all the information entered so far for this Delivery Order will be lost"
        cancelButtonText="Cancel"
        okButtonText="Confirm"
        onConfirmation={confirmRedirect}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SideDrawer);
