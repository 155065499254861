import { Warehouse } from './warehouses.types';
import { Customer } from './customers.types';
import { CustomerLocationOrder, Package, PackageGroup } from './deliveryOrders.types';
import { Transshipment } from './transshipment.types';
import { DSPTripStatus, DeliveryServiceProvider, DspJob } from './deliveryServiceProvider.types';
import { InteractionTypeEnum } from '../enums/interaction.enum';
import { PaymentMethod } from '@stripe/stripe-js';
import { Courier, LocationHistory, Vehicle } from './couriers.types';
import { TripOptimizingMethod } from '../enums/trips.enum';

//#region State interfaces

export interface TripOffer {
  tripOfferId: number;
  tripId: number;
  courierId: number;
  status: string;
}

export interface TripStopId {
  tripStopId: number;
}

export interface StopPackage {
  tripStopId: number;
  tripId: number;
  packageId: number;
  package: Package;
  fromWarehouse: boolean;
  warehouse: Warehouse;
  toCustomer: boolean;
  customer: Customer;
  interactionType: string;
  changeHandStatus: string;
  changeHandFailReason: string;
  deletedAt: null;
}

export interface StopLocation {
  locationId: number;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
  longitude: number;
  latitude: number;
  customer?: Customer;
  warehouse?: Warehouse;
  transshipment: Transshipment;
  label?: string;
}

export interface CourierTrackInfo {
  courierId: number;
  label?: string;
  trackedPoints: LocationHistory[];
}
export interface Stop {
  tripStopId: number;
  routerArrivalAt: string;
  routerDepartureAt: string;
  schedulerArrivalAt: string;
  schedulerDepartureAt: string;
  actualArrivalAt: string;
  actualDepartureAt: string;
  computedArrivalAt: string;
  computedDepartureAt: string;
  locationId: number;
  podPhoto: string;
  podSignature: string;
  tripId: number;
  trip?: Trip;
  stopPackages: StopPackage[];
  deletedAt: null;
  status: string;
  location: StopLocation;
  instructions: string;
  contactPhoneNumber: string;
  contactFullName: string;
  timeWindowFrom?: string;
  timeWindowTo?: string;
  shipToAddressee?: string;
  courierArrived?: boolean;
  packageGroups?: PackageGroup[];
  customerId?: number;
}

export interface TripAdditionalData {
  deliveryZoneId?: number;
  zoneCaption?: string;
  routingMethod?: string;
  invoiceTotal?: number;
  grossProfit?: number;
  colorPaletteIndex?: number;
  unvisibleOnMap?: boolean;
}

export interface Trip {
  tripId?: number;
  startsAt?: Date;
  confirmedAt?: Date;
  assignedAt?: Date;
  status: string;
  courierId: number | null;
  courier?: Courier;
  tripOffers: TripOffer[];
  //tripStops: TripStopId[];
  tripStops: Stop[];
  firstLeg: boolean;
  firstStop: number;
  numberOfPackages: number;
  numberOfRecipients: number;
  totalMiles: number;
  computedMiles?: number;
  computedReturnMiles?: number;
  computedReturnAt?: Date;
  computedReturnSource?: string;
  computingAlgorithm?: string;
  isRoundTrip: boolean;
  returnAt?: Date;
  dspJob?: DspJob;
  vehicleId?: number;
  vehicle?: Vehicle;
  additionalData?: TripAdditionalData;
}

export interface TripsOfDate {
  trips: Trip[];
  date: string;
}
export interface TripParking {
  tripParkingId: number;
  tripId: number;
  latitude: number;
  longitude: number;
  arrivalAt: Date;
  duration: number;
  algorithm: string;
  additionalData: any;
}
export interface TripArrangeStop {
  label: string;
  name?: string;
  companyName?: string;
  tripIndex: number;
  stopIndex: number;
  colorClass?: string;
  tripStopId: number;
  routerArrivalAt: Date;
  routerDepartureAt: Date;
  schedulerArrivalAt?: Date;
  schedulerDepartureAt?: Date;
  computedArrivalAt?: string;
  computedDepartureAt?: string;
  timeWindowFrom?: string;
  timeWindowTo?: string;
  location: StopLocation;
  shipToAddressee?: string;
  locationId?: number;
  warehouseId?: number;
  placeInfo?: StopPackagePlaceInfo;
  distanceFromPreviousStop?: number;
  stopPackages?: TripArrangeStopPackage[];
  additionalInfo?: string;
  status?: string;
  distanceFromPrevStop?: number;
  routePoints?: LatLng[];
}

export interface StopPackagePlaceInfo {
  placeId?: number;
  placeType?: 'CUSTOMER' | 'WAREHOUSE' | 'TRANSSHIPMENT';
}

export interface TripArrangeStopPackage {
  packageId: number;
  tripStopId?: number;
  interactionType?: InteractionTypeEnum;
  changeHandStatus?: string;
  changeHandFailReason?: string;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface CurbhubTrip {
  trip_id: string;
  courier_id: number;
  dspReferenceId?: string;
  dspPrice?: number;
  deliveryServiceProviderId?: number;
  first_leg: boolean;
  skippedStops?: boolean;
  stops: CurbhubTripStop[];
  isRoundTrip?: boolean;
  returnAt?: Date;
  totalMiles?: number;
}

export interface CurbhubTripStop {
  loc_id: number;
  arrival_time: Date;
  departure_time: Date;
  distance_from_previous_stop: number;
  connecting_trip_ids?: string[];
  packages: string[];
  transfer_type: string;
}
export const dateFormat = 'DD/MM/YY-HH:mm';
export interface TripWithStopsDetails {
  tripId?: number;
  warehouseId?: number;
  warehouseName?: string;
  startsAt?: Date;
  assignedAt?: Date;
  status: string;
  courierId: number | null;
  courier?: Courier;
  DSP?: DeliveryServiceProvider;
  dspRefrenceId?: string;
  dspTotalPrice?: number;
  dspRequiredInfo?: any;
  dspStatus?: DSPTripStatus;
  paymentMethod?: PaymentMethod;
  paymentUuid?: string;
  tripOffers: TripOffer[];
  tripStops: TripArrangeStop[];
  firstLeg: boolean;
  totalMiles: number;
  curbhubTrip: CurbhubTrip;
  doNotOptimize?: boolean;
  needsOptimize?: boolean;
  isRoundTrip?: boolean;
  returnAt?: Date;
  editable?: boolean;
  notEditableReason?: string;
  computedMiles?: number;
  computedReturnMiles?: number;
  computedReturnAt?: Date;
  computedReturnSource?: string;
  computingAlgorithm?: string;
  optimizingMethod?: TripOptimizingMethod;
  routingMethod?: string;
  additionalData?: TripAdditionalData;
}

export interface TripStopLabelStruct {
  tripId: number;
  tripStopId: number;
  label: string;
}

export interface TripReCalculateWithGoogleDirectionRequest {
  origin: LatLng;
  destination: LatLng;
  departureTime?: Date;
  wayPoints?: LatLng[];
  reArrange?: boolean;
}

export interface TripReCalculationRequest {
  trip: TripWithStopsDetails;
  reArrange?: boolean;
  startsAt?: Date;
  method: TripOptimizingMethod;
}

export interface TripDnDRecalculatingRequest {}
//#endregion

//#region Requests

export interface TripRequest {
  startsAt: string;
  deliveryOrderIds: number[];
  isRoundTrip?: boolean;
}

export interface ArrangeTripRequest {
  startsAt: string;
  packageGroupIds: number[];
  courierIds: number[];
  isRoundTrip?: boolean;
}

export interface EditTripRequest {
  date: Date;
}
export interface EditTripsResponse {
  trips: TripWithStopsDetails[];
  unAssignedStops?: TripArrangeStop[];
  customers?: Customer[];
  warehouses?: Warehouse[];
}

export interface CreateTripOfferRequest {
  courierIds: string[];
}

export interface TripOfferUpdateRequest {
  tripId: number;
  courierId?: number | null;
}

//#endregion Requests

//#region State interfaces

export interface TripsState {
  trips: Trip[] | null;
  unAssignedOrders: CustomerLocationOrder[] | null;
  loadingTrips: boolean;
  tripsFetched: boolean;
  loadingAliveTrips: boolean;
  aliveTripsFetched: boolean;
  tripsErrorMessage: string | null;
  stops: Stop[] | null;
  loadingStops: boolean;
  stopsFetched: boolean;
  stopsErrorMessage: string | null;
  creatingTrip: boolean;
  createTripSuccess: boolean;
  createTripErrorMessage: string | null;
  creatingTripOffer: boolean;
  createTripOfferSuccess: boolean;
  createTripOfferErrorMessage: string | null;
  deletingTripOffer: boolean;
  deleteTripOfferSuccess: boolean;
  deleteTripOfferErrorMessage: string | null;
}
export interface DeliveryZoneWithLocations {
  deliveryZoneId: number;
  caption: string;
  bindedLocations: StopLocation[];
  calculatedLocations: StopLocation[];
}
export interface DeliveryZoneWithLocationIds {
  deliveryZoneId?: number;
  caption: string;
  locationIds: number[];
}
//#endregion
