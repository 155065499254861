import { Courier, Customer, TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';
import './TripsDnd.styles.scss';
import TripDndTitleBar from './TripDndTitleBar';
import DndTimelineChart from '../DndTimelineChart/DndTimelineChart';
import moment from 'moment-timezone';
import { useState } from 'react';
import CourierSelector from './CourierSelector';
import OptimizationLock from './OptimizationLock';
import OptimizingGIF from './../../assets/images/duration-fetching.gif';
import TripDndItemToolTip from './TripDndItemToolTip';
import { customerLabel, getTimeWindowStatus } from '../../utils/trips.helper';
import TripDndItem from './TripDndItem';
import TripViewTripMetrics from './TripViewTripMetrics';
import { DSPQuote, DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import { ThirdPartyDeliveryQuoteDialog } from '../ThirdPartyDeliveryDialog/ThirdPartyDeliveryDialogs';
import { TripsChangeStructure, TripsChangeType } from '../../screens/Trips/TripChangeHandlers';
import { Warehouse } from '../../store/config/types/warehouses.types';
import { PaymentMethod } from '@stripe/stripe-js';
import { TripDndChangeTimeDialog } from './TripDndChangeTimeDialog';
import { ColorPalette } from '../../store/config/types/ColorSpectrum';
import { Grid, IconButton, Tooltip } from '@mui/material';
import TripDndUnassignedArea from './TripDndUnassignedArea';
import { Visibility } from '@material-ui/icons';

interface TripDndTimelineProps {
  trips: TripWithStopsDetails[];
  unAssignStops: TripArrangeStop[];
  couriers: Courier[];
  availableCouriersIds?: number[];
  deliveryServiceProviders?: DeliveryServiceProvider[];
  customers?: Customer[];
  selectedCusomerIds?: number[];
  warehouses?: Warehouse[];
  loading?: boolean;
  timezone?: string;
  disableDnd?: boolean;
  title?: string;
  showCurrentTime?: boolean;
  optimizing?: boolean;
  editMode?: boolean;
  noTitleBar?: boolean;
  routing?: boolean;
  viewportWidth?: number;
  /* eslint-disable no-unused-vars */
  // assignCourierToTrip?: (courier: Courier, tripIndex: number, unAssignFromIndex?: number) => void;
  onTripsChange?: (changeStructure: TripsChangeStructure) => void;
  onTripVisibilityOnMapChange?: (tripIndex: number, visible?: boolean) => void;
  onAllTripsVisibilityoggle?: (visible: boolean) => void;
  onReset?: () => void;
  onBeforeChange?: (changeStructures: TripsChangeStructure[]) => boolean;
  onOptimizeLockChange?: (tripIndex: number, value: boolean) => void;
  onOptimizeClick?: () => void;
  onZoneRoutingClick?: () => void;

  /* eslint-enable no-unused-vars */
}

export default function TripDndTimeline({
  title,
  timezone,
  trips,
  unAssignStops,
  customers,
  warehouses,
  couriers,
  availableCouriersIds,
  deliveryServiceProviders,
  optimizing,
  editMode,
  noTitleBar,
  routing,
  viewportWidth,
  onReset,
  onBeforeChange,
  onOptimizeLockChange,
  onOptimizeClick,
  onZoneRoutingClick,
  onTripVisibilityOnMapChange,
  onAllTripsVisibilityoggle,
}: TripDndTimelineProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCourierIds, setSelectedCourierIds] = useState<number[]>(
    trips.map((trip) => trip.courierId ?? 0).filter((id) => id !== null && id !== 0),
  );
  const [selectedDSP, setSelectedDSP] = useState<DeliveryServiceProvider | null>(null);
  const [destTripIndex, setDestTripIndex] = useState(-1);
  const [tripToChangeTime, setTripToChangeTime] = useState(-1);

  timezone && moment.tz.setDefault(timezone);
  // const tzOffset = moment().format('Z');
  const handleDSPdialogClose = () => {
    setDestTripIndex(-1);
    setSelectedDSP(null);
  };
  const handleDSPdialogAccept = (dsp: DeliveryServiceProvider, quote: DSPQuote, paymentMethod: PaymentMethod) => {
    if (onBeforeChange !== undefined && quote.trips.some((trip) => trip.status === 'QUOTED')) {
      selectCourier(destTripIndex, undefined, trips[destTripIndex].courierId ?? undefined);
      let changeChain: TripsChangeStructure[] = [
        {
          changeType: TripsChangeType.REMOVE_TRIP,
          sourceTripIdx: destTripIndex,
        },
      ];
      quote.trips
        .filter((trip) => trip.status === 'QUOTED')
        .forEach((trip) => {
          changeChain.push({
            changeType: TripsChangeType.ADD_NEW_TRIP,
            sourceStopPointers: trip.stops?.map((quoteStop) => ({
              aPackageId: quoteStop.firstPackageIdOfStop,
            })),
          });

          changeChain.push({
            changeType: TripsChangeType.TRIP_CUSTOM_CHANGE,
            customFunction: (trips, unAssignStops) => {
              const addedTripIndex = trips.length - 1;
              const addedTrip = trips[addedTripIndex];
              addedTrip.DSP = dsp;
              addedTrip.dspRequiredInfo = trip.requiredInfo;
              addedTrip.dspStatus = 'QUOTED';
              addedTrip.courierId = null;
              addedTrip.doNotOptimize = true;
              addedTrip.dspRefrenceId = trip.refrenceId;
              addedTrip.dspTotalPrice = trip.price;
              addedTrip.isRoundTrip = false;
              addedTrip.needsOptimize = false;
              addedTrip.paymentMethod = paymentMethod;

              if (trip.stops) {
                addedTrip.startsAt = trip.warehouse?.arrivalTime;
                addedTrip.tripStops[0].routerArrivalAt = moment(trip.warehouse?.arrivalTime).toDate();
                addedTrip.tripStops[0].routerDepartureAt = moment(trip.warehouse?.arrivalTime)
                  .add(5, 'minutes')
                  .toDate();
                trip.stops.forEach((stop, index) => {
                  if (stop.arrivalTime) {
                    addedTrip.tripStops[index + 1].routerArrivalAt = moment(stop.arrivalTime).toDate();
                    addedTrip.tripStops[index + 1].routerDepartureAt = moment(stop.arrivalTime)
                      .add(5, 'minutes')
                      .toDate();
                  }
                });
              }
              selectCourier(addedTripIndex, undefined, undefined, dsp.deliveryServiceProviderId);
              return { ok: true, trips, unAssignStops };
            },
          });
        });
      onBeforeChange(changeChain);
    }
    handleDSPdialogClose();
  };

  const handleBeforeDrop = (
    sourceRowIndex: number,
    sourceItemIndex: number,
    destRowIndex: number,
    destItemIndex: number,
  ): boolean => {
    if (destRowIndex !== undefined && destRowIndex >= 0 && trips[destRowIndex].dspRefrenceId) return false;
    if (onBeforeChange)
      return onBeforeChange([
        {
          changeType: TripsChangeType.STOP_DRAG_AND_DROP,
          sourceTripIdx: sourceRowIndex,
          sourceStopPointer: {
            tripIdx: sourceRowIndex,
            stopIdx: sourceItemIndex,
          },
          destTripIdx: destRowIndex,
          destStopPointer: {
            tripIdx: destRowIndex,
            stopIdx: destItemIndex,
          },
        },
      ]);
    else return true;
  };
  const handleBeforeAdd = (sourceRowIndex: number, sourceItemIndex: number): boolean => {
    if (onBeforeChange)
      return onBeforeChange([
        {
          changeType: TripsChangeType.ADD_NEW_TRIP,
          sourceTripIdx: sourceRowIndex,
          sourceStopPointers: [
            {
              tripIdx: sourceRowIndex,
              stopIdx: sourceItemIndex,
            },
          ],
        },
      ]);
    else return true;
  };
  const handleRoundTripChange = (tripIndex: number, value: boolean) => {
    trips[tripIndex].isRoundTrip = value;
    trips[tripIndex].needsOptimize = true;
    onOptimizeClick && onOptimizeClick();
  };
  const handleOptimizeLock = (rowIndex: number, value: boolean) => {
    // const value = !trips[rowIndex].doNotOptimize;
    if (!editMode) return;
    trips[rowIndex].doNotOptimize = value;
    onOptimizeLockChange && onOptimizeLockChange(rowIndex, value);
  };
  const selectCourier = (
    tripIndex: number,
    courierId: number | undefined,
    prevCourierId: number | undefined,
    deliveryServiceProviderId?: number,
  ): boolean => {
    const ids = Array.from(selectedCourierIds).filter((id) => id !== prevCourierId);
    if (deliveryServiceProviderId) {
      setSelectedDSP(
        deliveryServiceProviders?.find((dsp) => dsp.deliveryServiceProviderId === deliveryServiceProviderId) ?? null,
      );
      setDestTripIndex(tripIndex);
      return false;
    }
    if (courierId) ids.push(courierId);
    setSelectedCourierIds(ids);
    trips[tripIndex].courierId = courierId ?? null;
    trips[tripIndex].DSP = undefined;
    trips[tripIndex].dspRefrenceId = undefined;
    trips[tripIndex].dspTotalPrice = undefined;
    //trips[tripIndex].DeliveryServiceProviderId = DeliveryServiceProviderId;

    return true;
  };

  const handleChangeTimeClick = (tripIndex: number) => {
    setTripToChangeTime(tripIndex);
  };
  const handleChangeTime = (tripIndex: number, time: string) => {
    const change: TripsChangeStructure[] = [
      {
        changeType: TripsChangeType.TRIP_CUSTOM_CHANGE,
        customFunction: (trips, unAssignStops) => {
          if (trips[tripIndex].tripStops) {
            const tripStart = moment(trips[tripIndex].tripStops[0].routerArrivalAt).startOf('minute');
            const dayPart = moment(tripStart).format('YYYY-MM-DD');
            const newDate = moment(`${dayPart} ${time}:00`);
            if (newDate.isAfter(moment())) {
              const dateDiff = newDate.diff(tripStart, 'minutes');
              trips[tripIndex].startsAt = newDate.toDate();
              trips[tripIndex].tripStops.forEach((stop, stopIndex) => {
                trips[tripIndex].tripStops[stopIndex].routerArrivalAt = moment(
                  trips[tripIndex].tripStops[stopIndex].routerArrivalAt,
                )
                  .add(dateDiff, 'minutes')
                  .toDate();
                trips[tripIndex].tripStops[stopIndex].routerDepartureAt = moment(
                  trips[tripIndex].tripStops[stopIndex].routerDepartureAt,
                )
                  .add(dateDiff, 'minutes')
                  .toDate();
                trips[tripIndex].needsOptimize = true;
              });
            }
          }
          return { ok: true, trips, unAssignStops };
        },
      },
    ];

    onBeforeChange && onBeforeChange(change);
    setTripToChangeTime(-1);
  };

  trips.forEach((trip) => {
    const courierId = trip.tripOffers.find((offer) => offer.status === 'PENDING')?.courierId;
    if (courierId && !selectedCourierIds.includes(courierId)) selectedCourierIds.push(courierId);
  });
  const allNotVisible = trips.some((t) => t.additionalData?.unvisibleOnMap);
  return (
    <div className={`trips-dnd${noTitleBar ? ' no-border' : ''}`}>
      {!noTitleBar && (
        <TripDndTitleBar
          title={title}
          routing={routing}
          showOptimizeButton={trips.some((trip) => trip.needsOptimize && !trip.doNotOptimize)}
          // showZoneRoutingButton={(unAssignStops?.length ?? 0) > 0}
          searchQuery={searchQuery}
          onSearchQuery={(query) => {
            setSearchQuery(query);
          }}
          onReset={() => {
            onReset && onReset();
          }}
          onOptimizeClick={() => {
            onOptimizeClick && onOptimizeClick();
          }}
          onZoneRoutingClick={onZoneRoutingClick}
        />
      )}
      <div className="timeline-container">
        <DndTimelineChart
          timebandAdditionalAreaRender={() => (
            <Grid container pl={1}>
              {!!onAllTripsVisibilityoggle && (
                <IconButton
                  size="small"
                  onClick={() => {
                    onAllTripsVisibilityoggle(allNotVisible);
                  }}
                >
                  <Visibility
                    style={{
                      color: allNotVisible ? 'GrayText' : 'lightgray',
                      width: 20,
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          )}
          viewportWidth={viewportWidth}
          additionAreaWidth={150}
          rowEndAdditionalWidth={100}
          timezone={timezone}
          editMode={editMode}
          displayRecycle={true}
          rows={trips.map((trip, tripIndex) => {
            return {
              dropable: trip.dspRefrenceId === undefined && (trip.editable ?? true),
              className: trip.editable ? 'editable' : 'non-editable',
              items: trip.tripStops.map((stop, stopIndex) => {
                const customer = customers?.find((customer) => {
                  if (stop.placeInfo?.placeId)
                    return stop.placeInfo?.placeType === 'CUSTOMER' && customer.customerId === stop.placeInfo.placeId;
                  else return customer.locationId === stop.location?.locationId;
                });
                const warehouse = warehouses?.find((warehouse) => {
                  if (stop.placeInfo?.placeId)
                    return (
                      stop.placeInfo?.placeType === 'WAREHOUSE' && warehouse.warehouseId === stop.placeInfo.placeId
                    );
                  else return stop.warehouseId === warehouse.warehouseId;
                });
                const name =
                  (stopIndex > 0
                    ? customer
                      ? `${customer.firstName} ${customer.lastName}`
                      : stop.label
                    : warehouse
                    ? warehouse.name
                    : 'Warehouse') ?? '';
                // moment(`${stop.routerArrivalAt} ${tzOffset}`, 'DD/MM/YY-HH:mm Z'),

                return {
                  start: moment(stop.schedulerArrivalAt ?? stop.routerArrivalAt),
                  end: moment(stop.schedulerDepartureAt ?? stop.routerDepartureAt),
                  dragable: stopIndex > 0 && (trip.editable ?? true),
                  label: stopIndex > 0 ? (customer ? customerLabel(customer, stopIndex) : stop.label) : 'W',
                  // colorClass: makeColorClass(trip.additionalData?.colorPaletteIndex ?? tripIndex),
                  colorIndex: trip.additionalData?.colorPaletteIndex ?? tripIndex,
                  highlight:
                    stopIndex > 0 && searchQuery !== '' && name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
                  additionalInfo: {
                    name,
                    companyName: stopIndex > 0 ? (customer ? customer.companyName ?? '' : '') : trip.warehouseName,
                    skippReason: stop.additionalInfo,
                    arrive: stop.routerArrivalAt,
                    timeWindowFrom: stop.timeWindowFrom,
                    timeWindowTo: stop.timeWindowTo,
                    timeWindowStatus: getTimeWindowStatus(stop, 'NEW', trip.startsAt, timezone),
                    shipToAddressee: stop.shipToAddressee,
                  },
                };
              }),
            };
          })}
          recycleItems={unAssignStops.map((stop, stopIndex) => {
            const customer = stop.location.customer;
            const customerLbl = customerLabel(customer, stopIndex + 1);
            const name =
              stop.name ?? customer ? `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}` : stop.label;
            const searchComponents: string[] = [];
            if (customer) {
              [customer.companyName, customer.firstName, customer.lastName].forEach((info) => {
                if (info) searchComponents.push(info);
              });
            }
            const searchStr = searchComponents.join(' ');
            return {
              start: moment(stop.routerArrivalAt),
              end: moment(stop.routerDepartureAt),
              dragable: true,
              label: customerLbl,
              colorClass: stop.colorClass,
              // highlight: !!searchQuery && searchStr.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0,
              hidden: !!searchQuery && searchStr.toLowerCase().indexOf(searchQuery.toLowerCase()) < 0,
              additionalInfo: {
                name,
                companyName: customer ? customer.companyName ?? '' : '',
                skippReason: stop.additionalInfo,
                timeWindowFrom: stop.timeWindowFrom,
                timeWindowTo: stop.timeWindowTo,
                arrive: null,
                shipToAddressee: stop.shipToAddressee,
                searchStr,
              },
            };
          })}
          recycleAreaRender={() => (
            <TripDndUnassignedArea
              searchQuery={searchQuery}
              onRemoveFilter={() => {
                setSearchQuery('');
              }}
            />
          )}
          additionalAreaRender={(rowIndex) => (
            <div className="courier-area">
              {!!onTripVisibilityOnMapChange && (
                <span className="visible-on-map">
                  <IconButton
                    size="small"
                    onClick={() => {
                      onTripVisibilityOnMapChange(rowIndex, trips[rowIndex].additionalData?.unvisibleOnMap);
                    }}
                  >
                    <Visibility
                      style={{
                        color: trips[rowIndex].additionalData?.unvisibleOnMap ? 'lightgray' : 'GrayText',
                        width: 20,
                      }}
                    />
                  </IconButton>
                </span>
              )}
              <CourierSelector
                couriers={couriers}
                availableCouriersIds={availableCouriersIds}
                index={rowIndex}
                courierId={
                  trips[rowIndex].courierId ??
                  trips[rowIndex].tripOffers.find((offer) => offer.status === 'PENDING')?.courierId
                }
                DeliveryServiceProviders={deliveryServiceProviders}
                DeliveryServiceProviderId={trips[rowIndex].DSP?.deliveryServiceProviderId}
                selectedCourierIds={selectedCourierIds}
                onChange={selectCourier}
                editable={trips[rowIndex].editable}
              />
              {trips[rowIndex].needsOptimize && (
                <OptimizationLock
                  doNotOptimize={trips[rowIndex].doNotOptimize ?? false}
                  onChange={(value) => handleOptimizeLock(rowIndex, value)}
                />
              )}
            </div>
          )}
          spacerRender={(width, rowIndex, item, itemIndex) => {
            const pIndex = item?.colorIndex ?? rowIndex ?? 0;
            const pColor = ColorPalette[pIndex % ColorPalette.length];
            const colorStyle = { borderColor: pColor.dark40, backgroundColor: pColor.main };
            if (itemIndex === 0) return <div style={{ width }}></div>;
            if (optimizing && trips[rowIndex ?? 0].needsOptimize)
              return (
                <div style={{ width: 20, textAlign: 'center' }}>
                  <img src={OptimizingGIF} alt="Opt" />
                </div>
              );
            return <div className="dndt-row-spacer" style={{ width, ...colorStyle }}></div>;
          }}
          itemToolTipRender={(item, rowIndex, itemIndex) => (
            <TripDndItemToolTip
              item={item}
              onChangeTimeClick={
                itemIndex === 0
                  ? () => {
                      handleChangeTimeClick(rowIndex ?? 0);
                    }
                  : undefined
              }
            />
          )}
          itemRender={(item, rowIndex, itemIndex) => (
            <TripDndItem item={item} label={rowIndex! >= 0 && itemIndex ? `${itemIndex}` : undefined} />
          )}
          rowEndRender={(rowIndex) => {
            const trip = trips[rowIndex];
            const itemsCount = trip.tripStops.length;
            const totalMinutes =
              itemsCount > 1
                ? moment(trip.isRoundTrip ? trip.returnAt : trip.tripStops[itemsCount - 1].routerDepartureAt).diff(
                    trip.tripStops[0].routerArrivalAt,
                    'minutes',
                  )
                : 0;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const totalTime = `${hours > 0 ? hours + 'h' : ''}${minutes > 0 ? minutes + "'" : ''}`;
            return (
              <>
                <TripViewTripMetrics
                  trip={trip}
                  totalTime={totalTime}
                  showActions={editMode && !trip.dspRefrenceId}
                  onAction={(actionName, params) => handleRoundTripChange(rowIndex, params ?? false)}
                />
                {!trip.editable && (
                  <Tooltip arrow placement="top" title={trip.notEditableReason ?? 'Not editable'}>
                    <div className="dndt-row-not-editable"></div>
                  </Tooltip>
                )}
              </>
            );
          }}
          onBeforeDrop={handleBeforeDrop}
          onBeforeAddRow={handleBeforeAdd}
        />
      </div>
      <ThirdPartyDeliveryQuoteDialog
        trip={destTripIndex >= 0 ? trips[destTripIndex] : null}
        DSP={selectedDSP}
        open={selectedDSP !== null}
        customers={customers}
        timezone={timezone}
        onClose={handleDSPdialogClose}
        onAccept={handleDSPdialogAccept}
      />

      <TripDndChangeTimeDialog
        tripIndex={tripToChangeTime}
        currentTime={trips[tripToChangeTime]?.startsAt}
        onSetTime={handleChangeTime}
        onClose={() => {
          setTripToChangeTime(-1);
        }}
      />
    </div>
  );
}
