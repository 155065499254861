import { MoreVert, Visibility } from '@material-ui/icons';
import { Courier, Trip } from '../../store/config/types';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import moment from 'moment-timezone';

interface TripViewTripActionsProps {
  trip: Trip;
  couriers?: Courier[];
  timezone?: string;
  canComputeStops?: boolean;
  visible?: boolean;
  /* eslint-disable */
  onMenuSelect?: (tripId: number | undefined, menuAction: string) => void;
  onVisibilityChange?: (tripId: number, value: boolean) => void;
  /* eslint-enable */
}

export default function TripViewTripActions({
  trip,
  couriers,
  timezone,
  canComputeStops,
  visible,
  onMenuSelect,
  onVisibilityChange,
}: TripViewTripActionsProps) {
  const [moreMenuElement, setMoreMenuElement] = useState<HTMLElement | null>(null);

  timezone && moment.tz.setDefault(timezone);

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setMoreMenuElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMoreMenuElement(null);
  };
  const handleMenuSelect = (action: string) => {
    onMenuSelect && onMenuSelect(trip.tripId, action);
    handleMenuClose();
  };
  const courierId = trip.tripOffers.find((offer) => ['PENDING', 'ACCEPTED'].includes(offer.status))?.courierId;
  const courier = couriers?.find((courierItem) => courierItem.courierId === courierId);
  //couriers && trip.tripOffers[0] ? couriers.find((c) => c.courierId === trip.tripOffers[0].courierId) : undefined;
  const courierName = courier
    ? `${courier.firstName} ${courier.lastName}`
    : trip.dspJob
    ? `${trip.dspJob.deliveryServiceProvider?.displayName}` +
      (trip.dspJob?.supportReference ? ` (${trip.dspJob.supportReference})` : '')
    : //(trip.dspJob.courier.firstName ? `(${trip.dspJob.courier.firstName} ${trip.dspJob.courier.lastName})` : '')
      undefined;
  const tripDeletable =
    ['NEW', 'PENDING', 'CANCELLED'].includes(trip.status) &&
    !trip.dspJob &&
    !(trip.startsAt && moment(trip.startsAt).isSame(moment(), 'day'));
  const isNew = ['NEW'].includes(trip.status);
  const status = trip.status.toLowerCase().replaceAll('_', ' ');
  return (
    <div className={`courier-area ${trip.status}`}>
      <Button
        variant="outlined"
        size="small"
        className="trip-details-button"
        onClick={() => {
          handleMenuSelect('details');
        }}
      >
        Details
      </Button>
      {visible !== undefined && (
        <span className="visible-on-map">
          <IconButton
            size="small"
            onClick={() => {
              onVisibilityChange && onVisibilityChange(trip.tripId ?? 0, !visible);
            }}
          >
            <Visibility style={{ color: visible ? 'GrayText' : 'lightgray', width: 20 }} />
          </IconButton>
        </span>
      )}
      <span className="trip-menu">
        <IconButton size="small" onClick={handleMoreMenuClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="trips-menu"
          className="trip-popup-menu"
          anchorEl={moreMenuElement}
          open={moreMenuElement ? true : false}
          onClose={handleMenuClose}
        >
          <span className="menu-title">{`Trip #${trip.tripId}`}</span>
          <MenuItem
            key={`trip-details`}
            onClick={() => {
              handleMenuSelect('details');
            }}
          >
            <span>Details</span>
          </MenuItem>
          {isNew && courier && (
            <MenuItem
              key={`trip-remove-offer`}
              onClick={() => {
                handleMenuSelect('remove-offer');
              }}
            >
              <span>Remove Offer</span>
            </MenuItem>
          )}
          {!trip.dspJob && isNew && !courier && (
            <MenuItem
              key={`trip-offer`}
              onClick={() => {
                handleMenuSelect('offer');
              }}
            >
              <span>Offer to</span>
            </MenuItem>
          )}
          {trip.dspJob && trip.status === 'NEW' && (
            <MenuItem
              key={`cancel-third-party`}
              onClick={() => {
                handleMenuSelect('cancel-third-party');
              }}
            >
              <span>Cancel Third-party</span>
            </MenuItem>
          )}
          {trip.dspJob && (
            <MenuItem
              key={`third-party-support`}
              onClick={() => {
                handleMenuSelect('third-party-support');
              }}
            >
              <span>Need Support?</span>
            </MenuItem>
          )}
          {tripDeletable && (
            <MenuItem
              key={`trip-delete`}
              onClick={() => {
                handleMenuSelect('delete');
              }}
            >
              <span>Delete</span>
            </MenuItem>
          )}
          {canComputeStops && (
            <MenuItem
              key={`trip-compute`}
              onClick={() => {
                handleMenuSelect('compute-stops');
              }}
            >
              <span>Compute Stops</span>
            </MenuItem>
          )}
        </Menu>
      </span>
      <div className="trip-info">
        <div className="trip-status">{status.charAt(0).toLocaleUpperCase() + status.slice(1)}</div>
        <div className={`courier-label${courier ? '' : ' empty'} ${trip.status}`}>{courierName ?? 'Not Offered'}</div>
      </div>
    </div>
  );
}
