import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import {
  BlockTwoTone,
  BusinessTwoTone,
  CategoryTwoTone,
  ChevronLeft,
  Close,
  HotelTwoTone,
  LocationSearchingTwoTone,
  PlaceTwoTone,
} from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { PlaceInfo, PlacesService, sortPlacesByDistance } from '../../services/places.service';
import { LatLng, Stop, VehicleStopPurpose, VehicleStopType } from '../../store/config/types';
import { getCustomerDisplayName } from '../../utils/string.helpers';

interface IdentifyStopPanelProps {
  stopId: any;
  location: { latitude: number; longitude: number };
  titleBar?: boolean;
  options?: {
    displayImage?: boolean;
  };
  onClose?: () => void;
  // eslint-disable-next-line
  onAction?: (action: string, params?: any) => any;
}

export default function IdentifyStopPanel({
  stopId,
  location,
  titleBar,
  options,
  onClose,
  onAction,
}: IdentifyStopPanelProps) {
  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState<PlaceInfo[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<number>();

  useEffect(() => {
    setSelectedPlace(undefined);
  }, [places]);

  const handleNearbySearchClick = () => {
    if (location) {
      setLoading(true);
      PlacesService.placeNearbySearch(location)
        .then((data) => {
          if (data) {
            setPlaces(data.places.map((p, idx) => ({ ...p, placeId: idx })));
            if (onAction) {
              if (data?.vicinity) {
                onAction('title-change', `${location.latitude}, ${location.longitude} (${data.vicinity})`);
              }
              if (data.places.length) {
                const boundPoints: LatLng[] = [];
                data.places.forEach((p, pIndex) => {
                  boundPoints.push({ lat: p.latitude, lng: p.longitude });
                  p.label = pIndex + 1;
                });
                onAction('map-bound-change', boundPoints);
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCustomerSearchClick = () => {
    if (onAction) {
      const tripStops: Stop[] = onAction('get-trip-stops');
      const customerPlaces: PlaceInfo[] = tripStops
        .filter((ts) => ts.location?.customer)
        .map((ts, tsIndex) => ({
          latitude: ts.location.latitude,
          longitude: ts.location.longitude,
          name: getCustomerDisplayName(ts.location.customer as any),
          address: ts.location.streetAddress,
          type: 'CUSTOMER',
          label: tsIndex + 1,
          additionalData: { tripStopId: ts.tripStopId, customerId: ts.customerId },
          placeId: tsIndex,
        }));
      customerPlaces.sort((p1, p2) =>
        sortPlacesByDistance({ lat: location.latitude, lng: location.longitude }, p1, p2),
      );
      if (customerPlaces.length) {
        onAction('map-bound-change', [
          {
            lat: customerPlaces[0].latitude,
            lng: customerPlaces[0].longitude,
          },
          {
            lat: location.latitude,
            lng: location.longitude,
          },
        ]);
      }
      setPlaces(customerPlaces);
    }
  };

  const handleMenuActions = (menu: string) => {
    let identifiedType: VehicleStopType | undefined = undefined;
    let identifiedPurpose: VehicleStopPurpose | undefined = undefined;
    switch (menu) {
      case 'goto-main':
        setPlaces([]);
        setLoading(false);
        if (onAction) onAction('map-clear-markers');
        break;
      case 'Customer-Specific':
        handleCustomerSearchClick();
        break;
      case 'Search Nearby':
        handleNearbySearchClick();
        break;
      case 'General':
        identifiedType = 'UNRELATED';
        identifiedPurpose = 'GENERAL';
        break;
      case 'Overnight Stay':
        identifiedType = 'UNRELATED';
        identifiedPurpose = 'REST';
        break;
      case 'Pickup point':
        identifiedType = 'PICKUP';
        identifiedPurpose = 'PICKUP';
        break;
      case 'Unrelated Stop':
        identifiedType = 'UNRELATED';
        identifiedPurpose = 'UNRELATED';
        break;
      default:
        alert(menu);
    }
    if (identifiedType || identifiedPurpose) {
      if (onAction) onAction('identify-stop-type', { stopId, type: identifiedType, purpose: identifiedPurpose });
    }
  };

  const handlePlaceAction = (place: PlaceInfo, action: string) => {
    switch (action) {
      case 'customer':
        handleMenuActions('Customer-Specific');
        break;
      case 'unrelated':
        handleMenuActions('Unrelated Stop');
        break;
      case 'general':
        handleMenuActions('General');
        break;
      case 'select':
        if (onAction)
          onAction('identify-stop-type', {
            stopId,
            type: 'CUSTOMER',
            purpose: 'DROP_OFF',
            additionalData: place.additionalData,
          });
        break;
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  const handlePlaceHover = (place: PlaceInfo) => {
    if (onAction) {
      // onAction('map-focus', { lat: place.latitude, lng: place.longitude });
      onAction('map-display-markers', [
        { position: { lat: place.latitude, lng: place.longitude }, label: place.label },
      ]);
    }
  };
  const handlePlaceClick = (place: PlaceInfo) => {
    setSelectedPlace(place.placeId);
    if (onAction) {
      onAction('map-bound-change', [
        {
          lat: place.latitude,
          lng: place.longitude,
        },
        {
          lat: location.latitude,
          lng: location.longitude,
        },
      ]);
    }
  };

  return (
    <Grid container p={2} flexDirection="column">
      {/* Title bar */}
      {titleBar && (
        <Grid item>
          <Grid container flexWrap="nowrap" alignItems="center">
            <Grid item flexGrow={1}>
              {location.latitude},{location.longitude}
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose} size="small">
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        {places.length == 0 && !loading && <PlacesTypeMenu handleAction={handleMenuActions} />}
        {loading ? (
          <>
            <PlaceSkeleton />
            <PlaceSkeleton opacity={0.8} />
            <PlaceSkeleton opacity={0.6} />
            <PlaceSkeleton opacity={0.4} />
            <PlaceSkeleton opacity={0.2} />
          </>
        ) : (
          places.length > 0 && (
            <>
              <Grid container mb={2}>
                <IconButton
                  onClick={() => {
                    handleMenuActions('goto-main');
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              </Grid>
              {places.map((place, pIndex) => (
                <PlaceCard
                  key={pIndex}
                  place={place}
                  index={place.label ?? pIndex + 1}
                  options={options}
                  onHover={handlePlaceHover}
                  onClick={handlePlaceClick}
                  selected={place.placeId != undefined && place.placeId == selectedPlace}
                  onAction={handlePlaceAction}
                />
              ))}
            </>
          )
        )}
      </Grid>
    </Grid>
  );
}

const PlaceSkeleton = ({ opacity }: { opacity?: number }) => (
  <Grid container mb={2} spacing={1} style={{ opacity }}>
    <Grid item mt={1}>
      <Skeleton variant="rectangular" width={24} height={24} />
    </Grid>
    <Grid item flexGrow={1}>
      <Stack spacing={1}>
        <Grid container>
          <Grid item ml={1} pt={1}>
            <Grid container>
              <Grid item mr={1}>
                <Skeleton variant="circular" width={24} height={24} />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={150} height={24} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Skeleton variant="text" width={'100%'} />
        <Skeleton variant="text" width={'70%'} />
      </Stack>
    </Grid>
  </Grid>
);

const PlaceCard = ({
  place,
  index,
  selected,
  onClick,
  onHover,
  onAction,
}: {
  place: PlaceInfo;
  index: number;
  selected?: boolean;
  onClick: any;
  onHover: any;
  onAction: any;
  options?: any;
}) => {
  return (
    <Grid mb={2}>
      <Card>
        <CardActionArea onClick={() => onClick(place)} onMouseEnter={() => onHover(place)}>
          <Grid container flexWrap="nowrap" alignItems="stretch">
            <Grid item pt={2} pl={1}>
              <img
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: place.iconBackgroundColor ?? '#ccc',
                  maskImage: `url(${place.icon})`,
                  maskSize: '24px 24px',
                  maskRepeat: 'no-repeat',
                  borderRadius: 5,
                }}
              />
            </Grid>

            <Grid item flexGrow={1}>
              <CardContent>
                <Stack spacing={1}>
                  <Grid container flexWrap="nowrap">
                    <Grid item>
                      <Typography variant="subtitle1">
                        <Chip size="small" label={index} style={{ marginRight: 4 }} />
                        {place.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Typography variant="caption">{place.address}</Typography>
                  </Grid>
                </Stack>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
        {selected && (
          <CardActions>
            <Grid container justifyContent="end">
              {place.type == 'CUSTOMER' ? (
                <Button
                  onClick={() => {
                    onAction(place, 'select');
                  }}
                >
                  Select
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      onAction(place, 'general');
                    }}
                  >
                    General
                  </Button>
                  <Button
                    onClick={() => {
                      onAction(place, 'customer');
                    }}
                  >
                    Customer
                  </Button>
                  <Button
                    onClick={() => {
                      onAction(place, 'unrelated');
                    }}
                  >
                    Unrelated
                  </Button>
                </>
              )}
            </Grid>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

const PlacesTypeMenu = ({ handleAction }: { handleAction: any }) => {
  return (
    <>
      <PlacesTypeMenuItem
        title="General"
        description="Applicable to all customers on this trip."
        onAction={handleAction}
      />
      <PlacesTypeMenuItem
        title="Customer-Specific"
        description="Relevant to certain customer."
        icon={<BusinessTwoTone />}
        onAction={handleAction}
      />
      <PlacesTypeMenuItem
        title="Overnight Stay"
        description="Applicable to all customers on this trip."
        icon={<HotelTwoTone />}
        onAction={handleAction}
      />
      <PlacesTypeMenuItem
        title="Pickup point"
        description="Designate locations as pickup points."
        icon={<PlaceTwoTone />}
        onAction={handleAction}
      />
      <PlacesTypeMenuItem
        title="Unrelated Stop"
        description="Not associated with the current trip."
        icon={<BlockTwoTone />}
        onAction={handleAction}
      />
      <PlacesTypeMenuItem
        title="Search Nearby"
        description="Find locations or services near this area."
        icon={<LocationSearchingTwoTone />}
        onAction={handleAction}
      />
    </>
  );
};

const PlacesTypeMenuItem = ({
  title,
  description,
  icon,
  onAction,
}: {
  title: string;
  description?: string;
  icon?: any;
  onAction?: any;
}) => {
  return (
    <Grid container flexDirection="column" mb={2}>
      <Card>
        <CardActionArea
          onClick={() => {
            if (onAction) onAction(title);
          }}
        >
          <Grid container flexWrap="nowrap" alignItems="stretch">
            <Grid item pt={2} pl={1}>
              {icon ?? <CategoryTwoTone />}
            </Grid>
            <Grid item flexGrow={1}>
              <CardContent>
                <Stack spacing={1}>
                  <Grid container flexWrap="nowrap">
                    <Grid item>
                      <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                  </Grid>
                  {Boolean(description) && (
                    <Grid container>
                      <Typography variant="caption">{description}</Typography>
                    </Grid>
                  )}
                </Stack>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
// function getImageSource(place: PlaceInfo): string | undefined {
//   return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${place.photo?.reference}&key=${process.env.REACT_APP_GEOCODING_API_KEY}`;
// }
