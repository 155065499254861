import moment from 'moment-timezone';
import {
  PackageGroup,
  Stop,
  Trip,
  TripArrangeStop,
  TripParking,
  TripStopLabelStruct,
  TripWithStopsDetails,
} from '../../store/config/types';
import { getTimeWindowStatus } from '../../utils/trips.helper';
import { getCustomerDisplayName } from '../../utils/string.helpers';
import { LatLong } from '../../services/places.service';

export function stopToDndItem(
  trip: Trip | TripWithStopsDetails,
  stop: Stop | TripArrangeStop,
  tripIndex: number,
  stopIndex: number,
  isHighLight?: boolean,
  timezone?: string,
  displayComputed?: boolean,
  labels?: TripStopLabelStruct[],
) {
  {
    const statuses = stop.stopPackages?.map((stopPackage) => stopPackage.changeHandStatus) ?? [];

    let packagesStatus: string | null = null;
    let failedStatuses: (string | undefined)[] = statuses.filter((status) => status === 'FAIL');
    if (failedStatuses.length > 0)
      packagesStatus =
        failedStatuses.length > 0
          ? `${failedStatuses.length} Package${failedStatuses.length > 1 ? 's' : ''} failed`
          : null;

    failedStatuses = statuses.filter((status) => status === 'FAILED_BEFORE');
    if (failedStatuses.length > 0)
      packagesStatus =
        failedStatuses.length > 0
          ? `${failedStatuses.length} Package${failedStatuses.length > 1 ? 's' : ''} failed before`
          : null;
    // if (packagesStatus === '') packagesStatus = null;
    const delayedMinutes = Math.max(
      ['NEW', 'PENDING'].includes(trip.status) && trip.startsAt ? moment().diff(trip.startsAt, 'minute') : 0,
      0,
    );
    const start = moment(
      (displayComputed ? stop.computedArrivalAt : undefined) ?? stop.schedulerArrivalAt ?? stop.routerArrivalAt,
    );
    const end = moment(
      (displayComputed ? stop.computedDepartureAt : undefined) ?? stop.schedulerDepartureAt ?? stop.routerDepartureAt,
    );

    return {
      start,
      end,
      label: labels
        ? labels.find((label) => label.tripId === trip.tripId && stop.tripStopId === label.tripStopId)?.label ??
          `${stopIndex}`
        : `${stopIndex}`,
      // colorClass: displayComputed && !stop.computedArrivalAt ? 'computed-missed' : `color-unknown`,
      colorIndex: trip.additionalData?.colorPaletteIndex ?? tripIndex,
      additionalInfo: {
        tripStopId: stop.tripStopId,
        tripIndex: tripIndex,
        name: stop.location.customer
          ? `${stop.location.customer.firstName} ${stop.location.customer.lastName}`
          : undefined,
        companyName: stop.location.customer ? `${stop.location.customer.companyName ?? ''}` : undefined,
        warehouseName: stop.location.warehouse ? `${stop.location.warehouse.name ?? ''}` : undefined,
        arrive: stop.schedulerArrivalAt ?? stop.routerArrivalAt,
        departure: stop.schedulerDepartureAt ?? stop.routerDepartureAt,
        status: stop.status,
        timeWindowFrom: stopIndex > 0 ? stop.timeWindowFrom : null,
        timeWindowTo: stopIndex > 0 ? stop.timeWindowTo : null,
        timeWindowStatus:
          stopIndex > 0 ? getTimeWindowStatus(stop, trip.status, trip.startsAt, timezone, displayComputed) : 0,
        timeWindowAlertReason: delayedMinutes > 0 ? ' - Due to late start' : '',
        packagesStatus,
        shipToAddressee: stop.shipToAddressee,
      },
      highlight: isHighLight,
      hidden: displayComputed && !stop.computedArrivalAt,
    };
  }
}

export function getLabelsOfTripStops(trips: Trip[]) {
  const labels: TripStopLabelStruct[] = [];
  trips.forEach((trip) => {
    trip.tripStops?.forEach((stop, stopIndex) => {
      labels.push({
        tripId: trip.tripId ?? 0,
        tripStopId: stop.tripStopId,
        label: stopIndex > 0 ? `${stopIndex}` : 'W',
      });
    });
  });
  return labels;
}

export function getStopSequence(tripStops: Stop[] | TripArrangeStop[], sortByComptedTimes?: boolean) {
  const stopsSequence = tripStops
    .map((stop) => ({
      tripStopId: stop.tripStopId ? stop.tripStopId : stop.locationId,
      arrivalDate: sortByComptedTimes ? stop.computedArrivalAt : stop.routerArrivalAt,
    }))
    .filter((stopInfo) => stopInfo.arrivalDate);
  stopsSequence.sort((a: any, b: any) => moment(a.arrivalDate).unix() - moment(b.arrivalDate).unix());
  return stopsSequence;
}

export interface TripTimelineItem {
  arrival?: Date;
  departure?: Date;
  pathLength?: number;
  duration?: number;
  stopNumber?: number;
  info: {
    title: string;
    purpose: string;
    type: string;
    address?: string;
    packageGroups?: PackageGroup[];
    dwellCost?: number;
    transitCost?: number;
    allocatedCost?: number;
    vehicleStopId?: number;
  };
  location: LatLong;
  focused?: boolean;
  ref?: any;
  id?: number;
}

export function getAllocatedCostOfTripStop(tripStop?: Stop | null) {
  let allocatedCost = 0;
  tripStop?.packageGroups?.forEach((packageGroup) => {
    packageGroup.indirectCostsAttribution?.forEach((ic) => {
      allocatedCost += ic.cost;
    });
  });

  return allocatedCost;
}
export function getTripTimeline(
  trip: Trip,
  tripParkings?: TripParking[],
  computed?: boolean,
  focusedParkingId?: number,
) {
  const timeline: TripTimelineItem[] = [];
  if (computed) {
    tripParkings?.forEach((parking, pIndex) => {
      const tripStopIndex = trip.tripStops.findIndex(
        (ts) => pIndex == 0 || moment(ts.computedArrivalAt).isSame(parking.arrivalAt, 'second'),
      );

      const tripStop = tripStopIndex < 0 ? null : trip.tripStops[tripStopIndex];
      let stopTitle = '';
      if (tripStop?.location?.warehouse) {
        stopTitle = tripStop.location.warehouse.name ?? 'Warehouse';
      }
      if (tripStop?.location?.customer) {
        stopTitle = getCustomerDisplayName(tripStop.location.customer);
      }
      if (!stopTitle) {
        stopTitle = 'Unknown place';
      }
      const arrival = new Date(tripStop ? tripStop.computedArrivalAt : parking.arrivalAt);
      const departure = tripStop
        ? new Date(tripStop.computedDepartureAt)
        : new Date(arrival.valueOf() + parking.duration * 60000);
      const duration = (departure.valueOf() - arrival.valueOf()) / 60000;

      timeline.push({
        arrival,
        departure,
        pathLength: parking.additionalData?.pathLength,
        duration,
        stopNumber: tripStopIndex >= 0 ? tripStopIndex : undefined,
        info: {
          purpose: parking.additionalData?.purpose ?? 'UNKNOWN',
          title: stopTitle,
          type: parking.additionalData?.type ?? 'UNKNOWN',
          address: tripStop?.location.streetAddress ?? '',
          dwellCost: parking.additionalData?.dwellCost ?? 0,
          transitCost: parking.additionalData?.transitShareCost ?? 0,
          allocatedCost: getAllocatedCostOfTripStop(tripStop),
          vehicleStopId: parking.additionalData?.vehicleStopId ?? 0,
        },
        location: {
          latitude: parking.latitude,
          longitude: parking.longitude,
        },
        focused: parking.tripParkingId === focusedParkingId,
        id: parking.tripParkingId,
      });
    });
  } else {
    trip.tripStops.forEach((tripStop, stopNumber) => {
      let stopTitle = '';
      let type = 'WAREHOUSE';
      if (tripStop.location.warehouse) {
        stopTitle = tripStop.location.warehouse.name ?? 'Warehouse';
      }
      if (tripStop.location.customer) {
        stopTitle = getCustomerDisplayName(tripStop.location.customer);
        type = 'CUSTOMER';
      }
      const arrival = new Date(tripStop.routerArrivalAt);
      const departure = new Date(tripStop.routerDepartureAt);
      const duration = (departure.valueOf() - arrival.valueOf()) / 60000;
      timeline.push({
        arrival,
        departure,
        // pathLength: tripStop.,
        duration,
        stopNumber,
        info: {
          purpose: type == 'WAREHOUSE' ? 'PICKUP' : 'DROP_OFF',
          title: stopTitle,
          type,
          address: tripStop?.location.streetAddress ?? '',
          // dwellCost: parking.additionalData?.dwellCost ?? 0,
          // transitCost: parking.additionalData?.transitShareCost ?? 0,
        },
        location: {
          latitude: tripStop?.location.latitude,
          longitude: tripStop?.location.longitude,
        },
        id: tripStop.tripStopId,
      });
    });
  }
  return timeline;
}
