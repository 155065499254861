import { LatLng } from '../store/config/types';
import axios from './axios';

export interface LatLong {
  latitude: number;
  longitude: number;
}

export interface PlaceInfo {
  label?: number;
  name: string;
  address?: string;
  icon?: string;
  iconBackgroundColor?: string;
  iconMask?: string;
  latitude: number;
  longitude: number;
  photo?: {
    height: number;
    reference: string;
    width: number;
  };
  rating?: number;
  type: string;
  additionalData: any;
  placeId?: number;
}

export interface PlaceNearbySearchResponse {
  vicinity: string;
  places: PlaceInfo[];
}

async function placeNearbySearch(location: LatLong, mode?: 'GOOGLE_PLACES' | 'CUSTOMER') {
  if (mode == 'CUSTOMER') {
    const res = await axios.get(`/customer/nearby-search/${location.latitude},${location.longitude}`);
    return res.data.data as PlaceNearbySearchResponse;
  } else {
    const res = await axios.get(`/google/nearby-search/${location.latitude},${location.longitude}`);
    return res.data.data as PlaceNearbySearchResponse;
  }
}

export function sortPlacesByDistance(point: LatLng, p1: PlaceInfo, p2: PlaceInfo) {
  const dist1 = Math.sqrt((point.lat - p1.latitude) ** 2 + (point.lng - p1.longitude) ** 2);
  const dist2 = Math.sqrt((point.lat - p2.latitude) ** 2 + (point.lng - p2.longitude) ** 2);
  return dist1 - dist2;
}

export const PlacesService = { placeNearbySearch };
