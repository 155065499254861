import { Chip, Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Courier, Trip } from '../../store/config/types';
import { Close, Home } from '@material-ui/icons';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './TripInfoPanel.scss';
import tripsService from '../../services/trips.service';
import { getTripTimeline, TripTimelineItem } from './helper';
import moment from 'moment-timezone';
import { convertConstantLiteralToNormal } from '../../utils/string.helpers';
import TripInfoPanelTimelineItem from './TripInfoPanelTimelineItem';
import IdentifyStopPanel from '../IdentifyStopPanel/IdentifyStopPanel';
import { VehiclesService } from '../../services/vehicles.service';

interface TripInfoPanelProps {
  trip?: Trip;
  onClose?: () => void;
  couriers?: Courier[];
  timezone?: string;
  displayComputed?: boolean;
  colorIndex?: number;
  parkingId?: number;
  stopNumber?: number;
  adminMode?: boolean;
  // eslint-disable-next-line
  onAction?: (action: string, params?: any) => any;
}

export function TripInfoPanel({
  trip,
  onClose,
  couriers,
  timezone,
  displayComputed,
  colorIndex,
  parkingId,
  stopNumber,
  adminMode,
  onAction,
}: TripInfoPanelProps) {
  const [courier, setCourier] = useState<Courier>();
  const [timeline, setTimeline] = useState<TripTimelineItem[]>([]);

  const [totalCost, setTotalCost] = useState(0);
  const [totalMiles, setTotalMiles] = useState(0);
  const [stopToIdentifyIndex, setStopToIdentifyIndex] = useState<number>();
  const [stopIdentifyTitle, setStopIdentifyTitle] = useState<string>('');
  const timelineRef = useRef<any>();
  const timelineDateRef = useRef<Date>();
  const onParkingChangRef = useRef<any>();

  const PANEL_WIDTH = 420;
  const SEARCH_WIDTH = 430;

  onParkingChangRef.current = () => {
    console.log(`Trip: ${trip?.tripId}`);

    if (trip) {
      const tripParkings = tripsService.LocationsPathService.getTripParkings(trip.tripId ?? 0);
      setTimeline(getTripTimeline(trip, tripParkings, displayComputed, parkingId));
    }
  };

  const onParkingChanged = () => {
    if (onParkingChangRef.current) onParkingChangRef.current();
  };

  const closeStopIdentify = () => {
    setStopToIdentifyIndex(undefined);
    setStopIdentifyTitle('');
    handleActions('map-clear-markers');
  };
  const handleClose = () => {
    if (stopToIdentifyIndex != undefined) {
      closeStopIdentify();
    } else {
      if (onClose) onClose();
    }
  };

  const handleActions = (action: string, params?: any) => {
    switch (action) {
      case 'title-change':
        // setStopIdentifyTitle(params);
        break;
      case 'identify-stop':
        setStopToIdentifyIndex(params);
        setStopIdentifyTitle(`${timeline[params].location.latitude}, ${timeline[params].location.longitude}`);
        if (onAction)
          onAction('map-focus', { lat: timeline[params].location.latitude, lng: timeline[params].location.longitude });
        break;
      case 'identify-stop-type':
        const item = timeline.find((t) => t.id == params.stopId);
        if (item?.info.vehicleStopId) {
          const { type, purpose, additionalData } = params;
          VehiclesService.identifyVehicleStop(item.info.vehicleStopId ?? 0, type, purpose, additionalData).then(
            (data) => {
              if (data && onAction) {
                onAction('set-parking-additional-data', { tripParkingId: item.id, type, purpose });
              }
            },
          );
        }
        closeStopIdentify();
        // if (onAction) onAction(action, params);
        break;
      case 'set-highligh-stop':
        if (onAction && trip && params < trip?.tripStops.length) {
          const stopId = trip.tripStops[params].tripStopId;
          onAction('set-highligh-stop', stopId);
          onAction('map-focus', {
            lat: trip.tripStops[params].location.latitude,
            lng: trip.tripStops[params].location.longitude,
            zoom: 10,
          });
        }
        break;
      case 'unset-highligh-stop':
        if (onAction) {
          onAction('set-highligh-stop', undefined);
        }
        break;
      case 'get-trip-stops':
        return trip?.tripStops;

      default:
        if (onAction) onAction(action, params);
    }
  };

  function refreshRefs() {
    const itemElements: any[] = timelineRef.current.querySelectorAll('.trip-stops-timeline-item');
    itemElements.forEach((element, idx) => {
      timeline[idx].ref = element;
      if (timeline[idx].focused) {
        element.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling animation
          block: 'nearest', // Align the item nearest to the viewport
        });
      }
    });
  }

  useEffect(() => {
    closeStopIdentify();
    if (trip) {
      setCourier(couriers?.find((c) => c.courierId == trip.courierId));
      const tripParkings = tripsService.LocationsPathService.getTripParkings(trip.tripId ?? 0);

      setTimeline(getTripTimeline(trip, tripParkings, displayComputed, parkingId));
      const tCost = tripParkings.reduce((total, p, pIdx) => {
        if (pIdx < tripParkings.length - 1) {
          return total + (p.additionalData?.dwellCost ?? 0) + (p.additionalData?.transitShareCost ?? 0);
        } else {
          return total;
        }
      }, 0);
      setTotalCost(tCost);
      setTotalMiles(
        displayComputed ? (trip.computedMiles ?? 0) + (trip.computedReturnMiles ?? 0) : trip.totalMiles ?? 0,
      );
    } else {
      setCourier(undefined);
      setTimeline([]);
      setTotalCost(0);
      setTotalMiles(0);
    }
  }, [trip, displayComputed]);

  useEffect(() => {
    tripsService.LocationsPathService.registerOnChange(`TRIPS.INFOPANEL`, onParkingChanged);
    return () => {
      tripsService.LocationsPathService.unRegisterOnChange(`TRIPS.INFOPANEL`);
    };
  }, []);

  useLayoutEffect(() => {
    refreshRefs();
  }, [timeline]);

  useEffect(() => {
    if (!timeline.length || (stopNumber == undefined && parkingId == undefined)) {
      return;
    }

    let pIndex = 0;
    if (parkingId) {
      pIndex = timeline.findIndex((t) => t.id === parkingId);
    } else if (stopNumber) {
      pIndex = timeline.findIndex((t) => t.stopNumber == stopNumber);
    }

    if (pIndex < 0) {
      pIndex = 0;
    }
    if (pIndex >= 0) {
      if (timelineRef.current) {
        const itemElements: any[] = timelineRef.current.querySelectorAll('.focused');
        itemElements.forEach((element) => {
          element.classList.remove('focused');
        });
        if (timeline[pIndex].ref) {
          timeline[pIndex].ref.classList.add('focused');
          // scroll
          timeline[pIndex].ref.scrollIntoView({
            behavior: 'smooth', // Smooth scrolling animation
            block: 'nearest', // Align the item nearest to the viewport
          });
        }
      }
    }
    timelineDateRef.current = moment(trip?.assignedAt).toDate();
  }, [parkingId, stopNumber, timeline]);

  if (timezone) moment.tz.setDefault(timezone);

  return (
    <Collapse orientation="horizontal" in={Boolean(trip)}>
      <div className="trip-info-panel-container">
        <div
          className="width-provider"
          style={{ width: (stopToIdentifyIndex !== undefined ? PANEL_WIDTH + SEARCH_WIDTH : PANEL_WIDTH) + 1 }}
        ></div>
        <div className="trip-info-panel">
          <Grid container flexDirection="column" flexWrap="nowrap">
            <Grid item p={1} className="trip-info-panel-title">
              <Grid container flexDirection={'row'} alignItems="center" flexWrap="nowrap">
                <Grid item width={PANEL_WIDTH - (stopToIdentifyIndex !== undefined ? 16 : 50)} pl={1} pr={1}>
                  <Grid container flexDirection="row">
                    <Grid item flexGrow={1}>
                      <Grid container flexDirection="column">
                        <Typography variant="subtitle1">
                          {courier?.firstName} {courier?.lastName}
                        </Typography>

                        <Typography variant="subtitle2">
                          <Tooltip
                            title={
                              trip?.vehicle?.externalId
                                ? `ID: ${trip.vehicle.externalId} (${trip.vehicleId})`
                                : trip?.vehicleId
                                ? '#' + trip.vehicleId
                                : ''
                            }
                            arrow
                            placement="right"
                          >
                            <span>
                              {trip?.vehicle?.vehicleType} {trip?.vehicle?.licensePlate}
                            </span>
                          </Tooltip>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Chip label={convertConstantLiteralToNormal(trip?.status ?? '')} size="small" />
                    </Grid>
                    <Grid container flexDirection="row">
                      {Boolean(totalCost) && (
                        <Grid item flexGrow={1}>
                          <Typography variant="caption" color="GrayText">
                            Total Cost: ${totalCost.toFixed(0)}
                          </Typography>
                        </Grid>
                      )}
                      {Boolean(totalMiles) && (
                        <Grid item pr={1}>
                          <Typography variant="caption" color="GrayText">
                            {totalMiles.toFixed(0)} Miles
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item flexGrow={1}>
                  <Grid container>
                    <Grid item flexGrow={1} pl={2}>
                      {stopToIdentifyIndex !== undefined && (
                        <Grid container flexDirection="column">
                          <Grid item>
                            <Typography variant="body2">Identify Stop</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">{stopIdentifyTitle}</Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item>
                      <IconButton size="small" onClick={handleClose}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={1} overflow="hidden">
              <Grid container flexDirection="row" height="100%" flexWrap="nowrap">
                <Grid
                  item
                  style={{ width: PANEL_WIDTH, overflowY: stopToIdentifyIndex !== undefined ? 'hidden' : 'scroll' }}
                  className="trip-stops-timeline-wrapper"
                >
                  <Grid container>
                    <div
                      className="cover"
                      style={{ width: PANEL_WIDTH, display: stopToIdentifyIndex !== undefined ? 'block' : 'none' }}
                    ></div>
                    <Grid item flexDirection="column" p={1}>
                      <div className="trip-stops-timeline" ref={timelineRef}>
                        {timeline.map((item, index) => {
                          const displayDate = !moment(timelineDateRef.current).isSame(item.arrival, 'day');
                          if (displayDate) {
                            timelineDateRef.current = item.arrival;
                          }
                          return (
                            <TripInfoPanelTimelineItem
                              item={item}
                              itemIndex={index}
                              colorIndex={colorIndex}
                              displayDate={displayDate}
                              onAction={handleActions}
                              blur={stopToIdentifyIndex != undefined ? stopToIdentifyIndex != index : undefined}
                              adminMode={adminMode}
                              key={index}
                            />
                          );
                        })}

                        {displayComputed && (
                          <Grid container justifyContent="center" alignItems="end" mt={2} mb={4}>
                            <Home style={{ color: 'silver' }} />
                            <Typography variant="caption" color="GrayText">
                              {moment(trip?.computedReturnAt).format('MM/DD h:mm a')}
                            </Typography>
                          </Grid>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {stopToIdentifyIndex !== undefined && (
                  <Grid
                    item
                    width={SEARCH_WIDTH}
                    style={{ height: '100%', overflowY: 'scroll', scrollbarWidth: 'thin' }}
                  >
                    <IdentifyStopPanel
                      stopId={timeline[stopToIdentifyIndex].id}
                      location={timeline[stopToIdentifyIndex].location}
                      onAction={handleActions}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Collapse>
  );
}
