import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Customer, RootState, TripArrangeStop, TripWithStopsDetails } from '../../store/config/types';
import { fetchCouriers } from '../../store/actions/couriers.actions';
import { couriersService } from '../../services/couriers.service';
import tripsService from '../../services/trips.service';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTrips } from '../../store/actions/trips.actions';
import { DeliveryServiceProvider } from '../../store/config/types/deliveryServiceProvider.types';
import { Warehouse } from '../../store/config/types/warehouses.types';
import TripEditView from './TripEditView';
import { TripOptimizingMethod } from '../../store/config/enums/trips.enum';

// import TripRow from '../../components/TripsDnd/TripRow';

interface EditTripsParams {
  date: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    couriers: state.couriers,
    trips: state.trips,
    loggedIn: state.auth.loggedIn,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, any>) => ({
  fetchCouriers: () => dispatch(fetchCouriers()),
  fetchTrips: (from: Date | null = null, to: Date | null = null) => dispatch(fetchTrips(from, to)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TripEdit({ fetchCouriers, fetchTrips, couriers, loggedIn, auth }: PropsFromRedux) {
  if (auth.account?.shipper?.timezone) moment.tz.setDefault(auth.account?.shipper?.timezone);

  const { date } = useParams<EditTripsParams>();
  const history = useHistory();

  const [availableCouriers, setAvailableCouriers] = useState<number[]>([]);
  const [deliveryServiceProviders, setDeliveryServiceProviders] = useState<DeliveryServiceProvider[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  // const [searchText, setSearchText] = useState<string>('');
  const [arrangedTrips, setArrangedTrips] = useState<TripWithStopsDetails[] | undefined>(undefined);
  const [unAssignStops, setUnAssignStops] = useState<TripArrangeStop[]>([]);
  const [defaultOptimizingMethod, setDefaultOptimizingMethod] = useState<TripOptimizingMethod>(
    TripOptimizingMethod.NONE,
  );

  const fetchDeliveryServiceProviders = () => {
    setDeliveryServiceProviders([]);
    if (auth?.account?.shipper?.shipperId) {
      couriersService.fetchDeliveryServiceProviders().then((dsps) => setDeliveryServiceProviders(dsps ?? []));
    }
  };

  const handleCancel = () => {
    tripsService
      .cancelEditTrip()
      .then((result) => {
        if (result) history.goBack();
      })
      .finally(() => {
        // setSaving(false);
      });
  };

  const afterSave = () => {
    fetchTrips(moment(date, 'YYYY-MM-DD').toDate(), moment(date, 'YYYY-MM-DD').toDate()).finally(() => {
      history.goBack();
    });
  };

  useEffect(() => {
    fetchDeliveryServiceProviders();
  }, []);

  useEffect(() => {
    if (!couriers.loadingCouriers && !couriers.couriers && !couriers.couriersErrorMessage) {
      fetchCouriers();
    }
  }, [fetchCouriers, couriers.couriers, couriers.couriersErrorMessage, couriers.loadingCouriers, loggedIn]);

  useEffect(() => {
    if (date) {
      tripsService
        .fetchTripsOfDateForEdit({ date: moment(date).toDate() })
        .then((result) => {
          if (result?.defaultTripOptimizingMethod) setDefaultOptimizingMethod(result.defaultTripOptimizingMethod);
          if (result?.customers) setCustomers(result.customers);
          if (result?.warehouses) setWarehouses(result.warehouses);
          const trips = result?.trips;
          if (trips) {
            const unAssignedStops = trips.filter((trip) => trip.tripId === -1);
            setUnAssignStops(unAssignedStops.length > 0 ? unAssignedStops[0].tripStops : []);
            setArrangedTrips(JSON.parse(JSON.stringify(trips?.filter((trip) => trip.tripId !== -1))));
          }
        })
        .catch((e) => console.error(e));

      couriersService.fetchCouriersByDate(date).then((couriers) => {
        if (couriers) {
          setAvailableCouriers(couriers.map((c) => c.courierId ?? 0));
        }
      });
    }
  }, [date]);

  return (
    <TripEditView
      availableCourierIds={availableCouriers}
      couriers={couriers?.couriers ?? []}
      customers={customers}
      date={date}
      deliveryServiceProviders={deliveryServiceProviders}
      timezone={auth?.account?.timezone}
      trips={arrangedTrips ?? []}
      unassigned={unAssignStops ?? []}
      warehouses={warehouses}
      onCancel={handleCancel}
      onAfterSave={afterSave}
      defaultOptimizingMethod={defaultOptimizingMethod}
    />
  );
}

export default connector(TripEdit);
