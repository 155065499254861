import { TripTimelineItem } from './helper';
import { Button, Card, CardActions, Chip, Grid, Typography } from '@mui/material';
import { Event, LocationOn } from '@material-ui/icons';
import { ColorPalette } from '../../store/config/types/ColorSpectrum';
import moment from 'moment-timezone';
import { CSSProperties, useEffect, useRef } from 'react';
import { convertConstantLiteralToNormal, formatMinutes } from '../../utils/string.helpers';
import { CardActionArea } from '@material-ui/core';

interface TripInfoPanelTimelineItemProps {
  item: TripTimelineItem;
  itemIndex: number;
  displayDate?: boolean;
  colorIndex?: number;
  blur?: boolean;
  adminMode?: boolean;
  /* eslint-disable */
  onAction?: (action: string, params?: any) => void;
  setItemRef?: (item: TripTimelineItem, current: any) => void;
  /* eslint-enable */
}

export default function TripInfoPanelTimelineItem({
  item,
  itemIndex,
  colorIndex,
  displayDate,
  blur,
  adminMode,
  onAction,
  setItemRef,
}: TripInfoPanelTimelineItemProps) {
  const itemRef = useRef<any>();

  useEffect(() => {
    if (setItemRef && itemRef.current) setItemRef(item, itemRef.current);
  }, [itemRef]);

  const handleIdentifyClick = () => {
    if (onAction) {
      onAction('identify-stop', itemIndex);
    }
  };

  const handleCardClick = () => {
    if (onAction) {
      if (item.stopNumber != undefined) {
        onAction('set-highligh-stop', item.stopNumber);
      } else {
        onAction('unset-highligh-stop');
        onAction('map-focus', { lat: item.location.latitude, lng: item.location.longitude });
      }
    }
  };
  let typeColor: any = 'default';
  if (item.info.purpose == 'UNKNOWN') {
    typeColor = 'error';
  }
  let day = displayDate ? moment(item.arrival).format('MM/DD') : '';

  const stopNumberStyle: CSSProperties = {};
  const cardStyle: CSSProperties = {
    marginLeft: 16,
    paddingLeft: 16,
    position: 'relative',
    overflow: 'visible',
  };
  if (colorIndex !== undefined) {
    const color = ColorPalette[colorIndex % ColorPalette.length];
    // stopNumberStyle.borderColor = color.dark40;
    stopNumberStyle.backgroundColor = color.main;
    stopNumberStyle.color = color.dark40;
    stopNumberStyle.boxShadow = `0 0 0 2px ${color.dark40} inset`;
    // cardStyle.border = `1px solid ${color.dark40}`;
  }

  return (
    <div
      ref={itemRef}
      className={'trip-stops-timeline-item' + (item.focused ? ` focused` : '') + (blur ? ' blur' : '')}
      style={{ position: 'relative' }}
    >
      {Boolean(day) && (
        <Grid container justifyContent="center" alignItems="center" mt={4} mb={1}>
          <Event style={{ color: 'silver' }} />
          <Typography variant="caption" color="GrayText">
            {day}
          </Typography>
        </Grid>
      )}
      <Grid
        key={itemIndex}
        m={1}
        mt={4}
        style={{ backgroundColor: stopNumberStyle.color }}
        className="trip-stops-timeline-item-wrapper"
      >
        <Card style={cardStyle}>
          {item.stopNumber != undefined && (
            <span className="stop-number" style={stopNumberStyle}>
              {item.stopNumber ? item.stopNumber : 'W'}
            </span>
          )}
          <CardActionArea onClick={handleCardClick}>
            <Grid container p={1} pb={0}>
              <Grid item flexGrow={1}>
                <Typography variant="caption" color="gray">
                  {moment(item.arrival).format('h:mm a')}
                </Typography>
              </Grid>
              {item.duration && (
                <Grid item>
                  <Typography variant="caption" color="gray">
                    {formatMinutes(item.duration)}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container p={1}>
              <Grid item flexGrow={1}>
                <Typography variant="body1">{item.info.title}</Typography>
              </Grid>
              <Grid item>
                <Chip label={convertConstantLiteralToNormal(item.info.purpose)} size="small" color={typeColor} />
              </Grid>
            </Grid>
            {item.info.address && (
              <Grid container p={1}>
                <Grid item>
                  <Grid container flexDirection="row" flexWrap="nowrap">
                    <Grid item>
                      <LocationOn style={{ color: 'silver' }} />
                    </Grid>
                    <Grid item flexGrow={1}>
                      <Typography variant="body2" color="GrayText">
                        {item.info.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container p={1}>
              {(adminMode || item.stopNumber == 0) && Boolean(item.info.dwellCost) && (
                <Grid item flexGrow={1}>
                  <Typography variant="caption">
                    Stop Cost: ${((item.info.dwellCost ?? 0) + (item.info.transitCost ?? 0)).toFixed(0)}
                  </Typography>
                </Grid>
              )}
              {Boolean(item.info.allocatedCost) && (
                <Grid item>
                  <Typography variant="caption">
                    Allocated Cost: ${(item.info.allocatedCost ?? 0).toFixed(0)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardActionArea>
          {item.info.purpose == 'UNKNOWN' && (
            <CardActions>
              <Button disabled={blur !== undefined} onClick={handleIdentifyClick}>
                Identify Stop Type
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </div>
  );
}
