import moment from 'moment-timezone';
import { useRef, useState, useEffect, ReactElement } from 'react';
import { DndTimelineRecycleRow } from './DndTimelineRecycleRow';
import DndTimelineRow from './DndTimelineRow';
import DndTimelineTimes from './DndTimelineTimes';
import { DragDropContext, DragStart, DropResult, Droppable } from 'react-beautiful-dnd';

export const DND_TIMELINE_CHART_MIN_VIEWPORT_WIDTH = 200;

/* eslint-disable */
export interface DndTimeLineChartItem {
  start: moment.Moment;
  end: moment.Moment;
  dragable?: boolean;
  colorClass?: string;
  colorIndex?: number;
  label?: string;
  highlight?: boolean;
  additionalInfo?: any;
  hidden?: boolean;
}
export interface DndTimelineChartRow {
  index?: number;
  items: DndTimeLineChartItem[];
  dropable: boolean;
  className?: string;
  additionalInfo?: {
    colorIndex?: number;
  };
}
interface DndTimelineChartProps {
  rows: DndTimelineChartRow[];
  recycleItems?: DndTimeLineChartItem[];
  additionAreaWidth: number;
  showCurrentTime?: boolean;
  timezone?: string;
  displayRecycle?: boolean;
  rowEndAdditionalWidth?: number;
  editMode?: boolean;
  viewportWidth?: number;

  additionalAreaRender?: (rowIndex: number) => ReactElement;
  timebandAdditionalAreaRender?: () => ReactElement;
  recycleAreaRender?: (isDragStarts: boolean) => ReactElement;
  itemRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  itemToolTipRender?: (item: DndTimeLineChartItem, rowIndex?: number, itemIndex?: number) => ReactElement;
  spacerRender?: (width: number, rowIndex?: number, item?: DndTimeLineChartItem, itemIndex?: number) => ReactElement;
  rowEndRender?: (rowIndex: number) => ReactElement;
  onBeforeDrop?: (
    sourceRowIndex: number,
    sourceItemIndex: number,
    destRowIndex: number,
    destItemIndex: number,
  ) => boolean;
  onBeforeAddRow?: (sourceRowIndex: number, sourceItemIndex: number) => boolean;
  onBeforeDropToCustomComponent?: (
    sourceRowId: string,
    sourceItemIndex: number,
    destRowId: string,
    destItemIndex: number,
  ) => boolean;
  onTooltipOpen?: (rowIndex: number, itemIndex: number, isOpen: boolean) => void;
  onUnassignedTooltipOpen?: (itemIndex: number, isOpen: boolean) => void;
  onItemClick?: (item: DndTimeLineChartItem) => void;
  onItemDoubleClick?: (item: DndTimeLineChartItem) => void;
  onUnassignedItemClick?: (itemIndex: number) => void;
  onUnassignedItemDoubleClick?: (itemIndex: number) => void;
  /* eslint-enable */
}
export interface DndTimelineParameters {
  start?: moment.Moment;
  end?: moment.Moment;
  minorMinutes?: number;
  majorMinutes?: number;
  zoom?: number;
  viewportWidth?: number;
  timelineWidth?: number;
  minutesToPixel?: number;
}

const calcTimelineParameters = (Min2Px: number): DndTimelineParameters => {
  let minorMinutes = 2;
  let majorMinutes = 20;
  if (Min2Px < 8) {
    minorMinutes = 5;
    majorMinutes = 30;
  }
  if (Min2Px < 6) minorMinutes = 10;
  if (Min2Px < 4) minorMinutes = 20;

  if (Min2Px < 2) {
    minorMinutes = 30;
    majorMinutes = 60;
  }
  if (Min2Px < 1) {
    minorMinutes = 30;
    majorMinutes = 120;
  }
  if (Min2Px < 1 / 3) {
    minorMinutes = 60;
    majorMinutes = 180;
  }

  // console.log(Min2Px, minorMinutes);
  return { majorMinutes, minorMinutes };
};

export default function DndTimelineChart({
  rows,
  recycleItems,
  additionAreaWidth,
  showCurrentTime,
  timezone,
  displayRecycle,
  rowEndAdditionalWidth = 0,
  editMode = false,
  viewportWidth,
  additionalAreaRender,
  timebandAdditionalAreaRender,
  recycleAreaRender,
  itemRender,
  itemToolTipRender,
  spacerRender,
  rowEndRender,
  onBeforeDrop,
  onBeforeAddRow,
  onBeforeDropToCustomComponent,
  onItemClick,
  onItemDoubleClick,
  onUnassignedItemClick,
  onUnassignedItemDoubleClick,
}: DndTimelineChartProps) {
  const [isDragging, setIsDragging] = useState<string | null>(null);

  const [timelineParams, _setTimelineParams] = useState<DndTimelineParameters>({ zoom: 1, minutesToPixel: 1 });
  const [needsReRender, setNeedsReRender] = useState(false);
  const resizeTimerRef = useRef<any>();
  timezone && moment.tz.setDefault(timezone);
  // const canDragAndDrop = editMode ?? false;

  const setTimelineParams = (params: DndTimelineParameters) => {
    _setTimelineParams({
      start: (params.start ?? timelineParams.start)?.clone(),
      end: (params.end ?? timelineParams.end)?.clone(),
      majorMinutes: params.majorMinutes ?? timelineParams.majorMinutes,
      minorMinutes: params.minorMinutes ?? timelineParams.minorMinutes,
      zoom: params.zoom ?? timelineParams.zoom,
      viewportWidth: params.viewportWidth ?? timelineParams.viewportWidth,
      timelineWidth: params.timelineWidth ?? timelineParams.timelineWidth,
      minutesToPixel: params.minutesToPixel ?? timelineParams.minutesToPixel,
    });
  };

  // useLayoutEffect(() => {
  //   window.addEventListener('resize', () => {
  //     setViewportWidth();
  //   });
  //   setViewportWidth();
  // }, []);

  const calculateStartAndEndOfTimeline = () => {
    const res = rows.reduce(
      ({ start, end }, row) => {
        if (!row.items.length) return { start, end };
        if (row.items[0].hidden) return { start, end };
        const [rowStart, rowEnd] = [row.items[0].start, row.items[row.items.length - 1].end];

        return {
          start: !start || rowStart.unix() < moment(start).unix() ? rowStart.clone() : start,
          end: !end || rowEnd.unix() > moment(end).unix() ? rowEnd.clone() : end,
        };
      },
      { start: undefined, end: undefined } as DndTimelineParameters,
    );
    setTimelineParams(res);
    setNeedsReRender(true);
  };
  useEffect(() => {
    calculateStartAndEndOfTimeline();
  }, [rows]);

  useEffect(() => {
    if (
      viewportWidth &&
      timelineParams.start &&
      timelineParams.end &&
      timelineParams.end.unix() > timelineParams.start.unix()
    ) {
      const extendedWidth = 80 + rowEndAdditionalWidth;
      const timelineWidth = Math.max(
        viewportWidth - additionAreaWidth - extendedWidth,
        DND_TIMELINE_CHART_MIN_VIEWPORT_WIDTH,
      );
      const duration = timelineParams.end.diff(timelineParams.start, 'minutes');
      const minutesToPixel = Math.min(timelineWidth / duration, 10);
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current);
      resizeTimerRef.current = setTimeout(() => {
        setTimelineParams({
          ...calcTimelineParameters(minutesToPixel),
          timelineWidth,
          viewportWidth,
          minutesToPixel,
        });
      }, 300);
    }
    if (needsReRender) setNeedsReRender(false);
  }, [needsReRender, viewportWidth]);

  const extractRowIndex = (rowId?: string): number => {
    const isaRow = /r[0-9]+/.test(rowId ?? '');
    if (isaRow && rowId) return +rowId?.substring(1);
    if (rowId === 'unassigned') return -1;
    return -2;
    // else return -2
  };
  const handleDragEnd = (result: DropResult /*, provided: ResponderProvided*/) => {
    const { destination, source } = result;
    setIsDragging(null);
    const sourceRowIndex = extractRowIndex(source.droppableId);
    const sourceItemIndex = source.index;
    const destRowIndex = extractRowIndex(destination?.droppableId);
    const destItemIndex = destination?.index ?? 0;

    // if sourse Index or dest Index <-1 call with  its id

    if (destination?.droppableId === 'newline') {
      //new line
      if (onBeforeAddRow ? onBeforeAddRow(sourceRowIndex, sourceItemIndex) : true) {
        // calculateStartAndEndOfTimeline();
      }
    } else if (sourceRowIndex < -1 || destRowIndex < -1) {
      if (
        onBeforeDropToCustomComponent
          ? onBeforeDropToCustomComponent(
              source.droppableId,
              sourceItemIndex,
              destination?.droppableId ?? '',
              destItemIndex,
            )
          : true
      ) {
        // handle custom component drop
      }
      calculateStartAndEndOfTimeline();
    } else if (onBeforeDrop ? onBeforeDrop(sourceRowIndex, sourceItemIndex, destRowIndex, destItemIndex) : true) {
      // const item = (sourceRowIndex >= 0 ? rows[sourceRowIndex].items : recycleItems).splice(sourceItemIndex, 1);
      // (destRowIndex >= 0 ? rows[destRowIndex].items : recycleItems).splice(destItemIndex, 0, ...item);
      calculateStartAndEndOfTimeline();
    }
  };
  const handleDragStart = (start: DragStart) => {
    setIsDragging(start.draggableId);
  };

  return (
    <div
      className="dnd-timeline"
      onClick={() => {
        setNeedsReRender(true);
      }}
    >
      <DndTimelineTimes
        additionAreaWidth={additionAreaWidth}
        additionalAreaRender={timebandAdditionalAreaRender}
        minutesToPixel={(timelineParams.minutesToPixel ?? 1) * (timelineParams.zoom ?? 1)}
        timelineWidth={timelineParams.timelineWidth}
        start={timelineParams.start}
        end={timelineParams.end}
        minorMinutes={timelineParams.minorMinutes}
        majorMinutes={timelineParams.majorMinutes}
        showCurrentTime={showCurrentTime}
        timezone={timezone}
      />
      <DragDropContext onDragEnd={handleDragEnd} onBeforeDragStart={handleDragStart}>
        <div className="dnd-chart">
          {rows.map((row, index) => (
            <DndTimelineRow
              row={row}
              start={timelineParams.start}
              rowIndex={index}
              key={`row-${index}`}
              additionAreaWidth={additionAreaWidth}
              minutesToPixel={(timelineParams.minutesToPixel ?? 1) * (timelineParams.zoom ?? 1)}
              additionalAreaRender={additionalAreaRender}
              itemRender={itemRender}
              itemToolTipRender={itemToolTipRender}
              spacerRender={spacerRender}
              rowEndRender={rowEndRender}
              disableDragAndDrop={!editMode}
              onItemClick={onItemClick}
              onItemDoubleClick={onItemDoubleClick}
              // onTooltipOpen={onTooltipOpen}
            />
          ))}
          {/* {displayNewRowButton && (
          <IconButton size="small" className="dndt-new-row-button" onClick={() => handleNewRowClick()}>
            <Add />
          </IconButton>
        )} */}
          {editMode && (
            <Droppable droppableId={`newline`} direction="horizontal">
              {(provided, snapshot) => (
                <div
                  className={`dndt-row dndt-new-row${snapshot.isDraggingOver ? ' highlight' : ''}`}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="dndt-addition-area" style={{ width: additionAreaWidth ?? 24, textAlign: 'center' }}>
                    <span className="dndt-row-icon">NEW</span>
                  </div>
                </div>
              )}
            </Droppable>
          )}
        </div>
        <div className="dnd-footer">
          <DndTimelineRecycleRow
            items={recycleItems ?? []}
            additionAreaWidth={additionAreaWidth}
            additionAreaRender={recycleAreaRender}
            isDragging={isDragging !== null}
            minutesToPixel={(timelineParams.minutesToPixel ?? 1) * (timelineParams.zoom ?? 1)}
            visible={displayRecycle}
            itemRender={itemRender}
            itemToolTipRender={itemToolTipRender}
            onItemClick={onUnassignedItemClick}
            onItemDoubleClick={onUnassignedItemDoubleClick}
            // onTooltipOpen={onUnassignedTooltipOpen}
          />
        </div>
      </DragDropContext>

      {/* <DndTimelineZoomPanel /> */}
    </div>
  );
}
